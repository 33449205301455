import React from "react"

import logo from "../utils/meg-image.jpg"
import Layout from "../components/layout"
import SEO from "../components/seo"

const Home = props => {
  const title = "Meghna Srivastava"

  return (
    <Layout location={props.location} title={title}>
      <SEO title="Frontend Developer | Meghna Srivastava" />

      <img src={logo} alt="Logo" className="home" />
    </Layout>
  )
}

export default Home
